@font-face {
  font-family: "Open Sans";
  src: url("static/assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("static/assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans ExtraBold";
  src: url("static/assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans Light";
  src: url("static/assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans SemiBold";
  src: url("static/assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*Muli*/

@font-face {
  font-family: "Muli";
  src: url("static/assets/fonts/Muli-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Bold";
  src: url("static/assets/fonts/Muli-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Black";
  src: url("static/assets/fonts/Muli-Black.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli ExtraBold";
  src: url("static/assets/fonts/Muli-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli Light";
  src: url("static/assets/fonts/Muli-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli SemiBold";
  src: url("static/assets/fonts/Muli-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
