body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-container {
  .modal-content {
    @media screen and (min-width: "1280px") {
      border-radius: 8px;
      width: calc(100vw - 220px);
      height: calc(100vh - 60px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12%, -28%);
    }

    @media screen and (min-width: "1440px") {
      box-shadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.17);
      border-radius: 16px;
      width: calc(100vw - 220px);
      height: calc(100vh - 60px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-8%, -36%);
    }
  }
}

.modal-alert {
  .modal-content {
    box-shadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.17);
    border-radius: 16px;
    width: 352px;
    height: 226px;
  }
}
